import React, { useEffect } from 'react';
import { InfoRow, ColumnFlexContainer } from '../shared/total-sheet/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import dayjs from 'dayjs';
import ViewItem from '../shared/ViewItem';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '#Services';
import Loader from '#Components/shared/Loader';
import { calculateTotalItems, getTimeSlotFromDateString } from '#Utils/helpers';
import { useParams } from 'react-router-dom';
const api = new ApiService();
const TotalSheetDetails = ({ returnId }) => {
    const { clientId } = useParams();
    const { storageReturnData } = useStores();
    const clientData = useQuery({
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    const { data, isLoading, error } = useQuery({
        enabled: !!returnId,
        queryKey: ['returnItem', returnId],
        retryDelay: 4000,
        queryFn: ({ queryKey }) => {
            const [_, returnId] = queryKey;
            return api.getReturnOrder(returnId);
        },
    });
    useEffect(() => {
        if (data?.stage) {
            storageReturnData.setCurrentStatus(data?.stage);
        }
    }, [data?.stage]);
    useErrorToastEffect(error, 'Error with price calculation');
    if (isLoading || clientData.isLoading) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnFlexContainer, null,
            React.createElement(InfoRow, { "$bold": true },
                React.createElement("span", null, "Order"),
                React.createElement("span", null,
                    "#",
                    data?.hubspot_order_id)),
            React.createElement(InfoRow, { "$bold": true },
                React.createElement("span", null, "Price"),
                React.createElement("span", { style: { fontSize: 24, lineHeight: '32px' } },
                    "\u00A3",
                    data?.total_price)),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Date"),
                React.createElement("span", null, dayjs(data?.order_date).format('DD MMMM YYYY'))),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Time slot"),
                React.createElement("span", null, `${getTimeSlotFromDateString(data?.time_start || '00')}:00-${getTimeSlotFromDateString(data?.time_end || '00')}:00`)),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Address"),
                React.createElement("span", null, data?.dropoff_address)),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Client"),
                React.createElement("span", { style: { wordBreak: 'break-all' } },
                    clientData.data?.email || 'No email specified',
                    React.createElement("br", null),
                    clientData.data?.phone || 'No phone number specified'))),
        data?.items.map((item, index) => (React.createElement(ViewItem, { amount: item.amount, comment: item.comment, title: item.title, key: index }))),
        React.createElement("p", { style: { textAlign: 'right', marginTop: 12 } },
            "Total items: ",
            calculateTotalItems(data?.items || []))));
};
export default observer(TotalSheetDetails);
